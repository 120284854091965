import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';
import { InfiniteScroll } from 'ui/molecules';
import { useRequest, useStateHandlers } from 'hooks';
import { UsersApi } from 'lib/api';
import { User } from 'lib/api/users/users.types';
import { pageSize } from 'lib/globalVars';

interface UsersRequest {
  data: User[];
  total: number;
}

interface StateType {
  page: number;
  query?: string;
  users: number | number[];
}

const { Option } = Select;

interface AdminUsersListProps extends SelectProps<number | number[]> {
  query?: string;
  parentsNodeClassName?: string;
  params?: object;
}

export const AdminUsersList = forwardRef<Select<number | number[]>, AdminUsersListProps>(
  ({ query, parentsNodeClassName, defaultActiveFirstOption, params, ...props }, ref) => {
    const { t } = useIntl();
    const users = useRequest<UsersRequest>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, users: [], query });

    useEffect(() => {
      users.request(
        UsersApi.listAdminUsers({
          per_page: pageSize,
          page: state.page,
          query: state.query,
          is_staff: true,
          ...params,
        }),
        state.page > 1 ? { concat: 'data' } : {},
      );
    }, [state.page, state.query, params]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    const hasMore = useMemo(() => !users?.loading && Math.ceil(users?.data?.total / pageSize) > state?.page, [
      users.loading,
      users.data,
      state.page,
      defaultActiveFirstOption,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (users: number | number[]) => {
      setState({ users });
    };

    return (
      <Select
        ref={ref}
        loading={users.loading}
        placeholder={t('pleaseSelectA', { item: t('user').toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll onLoadMore={onPageChange} isDirectChild hasMore={true}>
            {menu}

            {users?.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {users?.data?.data?.map(({ id, full_name }) => (
          <Option key={id} value={id}>
            {full_name}
          </Option>
        ))}
      </Select>
    );
  },
);
