import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, RedirectProps } from 'react-router';

import { objToQuery, parseQuery } from 'lib/utils';
import { $Object, getOnlyExistingKeys, withValues } from 'lib/object';
import { SessionStorage } from 'lib';

export type Result<T> = {
  filters: T;
  onFiltersChange: (newValues: Partial<T>, options?: OnFiltersChangeOptions) => void;
};

type Options<T> = {
  keyword: string;
  useQueryParams?: boolean;
  useDefaultValue?: (keyof T)[];
  clearDefaultValue?: (filters: T) => boolean;
};

export interface OnFiltersChangeOptions {
  reset?: boolean;
  replace?: boolean;
  mutateLocalStorage?: boolean;
}

export const useFilters = <T extends object>(
  initialFilters: T,
  options: Options<T>,
  withoutStoredFilters?: boolean,
): Result<T> => {
  const storedFilters = SessionStorage.getFilterStorage();

  const location = useLocation();
  const history = useHistory();

  const localFilters = React.useRef<$Object>(storedFilters ? JSON.parse(storedFilters) : {});

  const query = React.useMemo(() => parseQuery(location.search), [location]) as T;

  const strToNumbers = (obj: $Object) =>
    JSON.parse(
      JSON.stringify(obj, (_, value) =>
        value?.trim?.() === '' ||
        typeof value === 'object' ||
        isNaN(value) ||
        value?.startsWith?.('0') ||
        value?.startsWith?.('+')
          ? value
          : +value,
      ),
    );

  const pushCurrentRoute = (params: $Object, replace?: boolean) => {
    const args: RedirectProps['to'] = {
      pathname: location.pathname,
      hash: location.hash,
      search: objToQuery(withValues({ ...query, ...params }), {
        array: 'withKeys',
      }),
    };

    replace ? history.replace(args) : history.push(args);
  };

  const clearDefaultValue = (filters: T) => {
    const clearDefaultFilters = !!options.clearDefaultValue?.(filters);

    if (clearDefaultFilters) {
      options.useDefaultValue?.forEach(key => {
        delete filters[key];
      });
    }
  };

  const currentFilters = useMemo<T>(() => {
    localFilters.current =
      storedFilters && !withoutStoredFilters && !Object.keys(location?.search)?.length ? JSON.parse(storedFilters) : {};

    return strToNumbers(
      getOnlyExistingKeys(
        initialFilters,
        Object.keys(query).length > 0 && !options.useQueryParams
          ? {
              ...initialFilters,
              ...localFilters.current[options.keyword],
              ...query,
            }
          : localFilters.current[options.keyword]
          ? { ...initialFilters, ...localFilters.current[options.keyword] }
          : withValues(initialFilters),
      ),
    ) as T;
  }, [options.keyword]);

  const [filters, setFilters] = useState(currentFilters);

  useEffect(() => {
    clearDefaultValue(currentFilters);

    setFilters(currentFilters);
  }, [currentFilters]);

  const onFiltersChange = (
    newValues: $Object,
    optionsFilters: OnFiltersChangeOptions = {
      replace: false,
      mutateLocalStorage: false,
      reset: false,
    },
  ) => {
    const { replace, mutateLocalStorage, reset } = optionsFilters;
    const newFilters = {
      ...(!reset && filters),
      page: 1,
      ...newValues,
    } as T;

    const stateFilters = { ...newFilters };

    if (options.useDefaultValue?.length) {
      options.useDefaultValue.forEach(key => {
        delete newFilters[key];
      });
    }

    clearDefaultValue(stateFilters);

    setFilters(stateFilters);
    SessionStorage.setFiltersStorage({
      ...localFilters.current,
      [options.keyword]: newFilters,
    });
    !mutateLocalStorage && pushCurrentRoute(newFilters, replace);
  };

  return {
    filters,
    onFiltersChange,
  };
};
